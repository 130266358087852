<div class="main">
  <div fxLayout fxLayout.lt-md="column">
    <div class="card">
      <div class="card-header">
        We help you to protect your privacy
      </div>
      <div class="card-body">
        <ul>
          <li>Do you store personal information and data on your computer or in the cloud ?</li>
          <li> But is it protected ?</li>
          <li>What if a hacker gains access ?</li>
        </ul>
      </div>
      <div class="card-bottom">
        Encryption hides the content of your files.
        <br> Only you can see what is in them.
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        Encryption for everyone
      </div>
      <div class="card-body">
        <ul>
          <li>Don't wait for Apple or Google or Microsoft to encrypt your files.</li>
          <li>Do it yourself !</li>
        </ul>
      </div>
      <div class="card-bottom">Encryption uses a key to lock your files:
        <br>make sure only you have the key !
      </div>
    </div>    
  </div>
  <div fxLayout fxLayout.lt-md="column">
    <div class="card">
      <div class="card-header">
        Encryption can be simple and easy to use
      </div>
      <div class="card-body">
        <ul>
          <li>Here (on this website) you will find the tools to easily encrypt your files.</li>
          <li>And, of course, our tools allow you to decrypt the same files with your key !</li>
          <li>We offer
            <strong>free</strong> and easy to use encryption and decryption tools.
          </li>
        </ul>
      </div>
      <div class="card-bottom">
        Nothing to install on your computer: <br> conveniently run on our website.
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        Our tools let you manage your files securely
      </div>
      <div class="card-body">
        <ul>
          <li>And for our members we offer a full range of advanced encryption using the latest algorithms and super key
            strength
            !
          </li>
          <li>Our members have multiple ways to handle and store their files.</li>
          <li> Since "keys" are the "key" to the privacy of your files, we offer multiple ways to create, upload,
            maintain, and store
            keys.
          </li>
        </ul>
      </div>
      <div class="card-bottom">
        We help you keep your keys safe !
      </div>
    </div>
  </div>

  <div id="dimLogo"></div>
</div>