<mat-toolbar class="top-banner" color="primary">
  <button mat-button routerLink=".">
    <span class="mat-headline">{{ title }}</span>
  </button>
  <div fxFlex="1 1 auto"></div>
  <div fxHide fxShow.gt-xs>
    <div fxLayout="row">
      <div *ngIf="!this.authService.isLoggedIn">
        <a mat-button [routerLink]="['tools']">
          <span class="mat-title">Tools</span>
        </a>
      </div>
      <div *ngIf="this.authService.isLoggedIn">
        <a mat-button [routerLink]="['member']">
          <span class="mat-title">Member Tools</span>
        </a>
      </div>
      <a mat-button routerLink="links"><span class="mat-title">Links</span></a>
      <button mat-button [matMenuTriggerFor]="menu">
        <span class="mat-title">{{ authService.displayName }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item [disabled]="this.authService.isLoggedIn" (click)="openDialog()">
          login
        </button>
        <button mat-menu-item [disabled]="!this.authService.isLoggedIn" (click)="doLogout()">
          logout
        </button>
        <a mat-menu-item [disabled]="this.authService.isLoggedIn" routerLink="register">
          register
        </a>
      </mat-menu>
      <a mat-button routerLink="help"><span class="mat-title">Help</span></a>
    </div>
  </div>
  <div fxHide fxShow.xs>
    <button mat-button [matMenuTriggerFor]="navSubMenu">
       <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #navSubMenu="matMenu">
      <div *ngIf="!this.authService.isLoggedIn">
        <a mat-menu-item [routerLink]="['tools']">
          <span class="mat-title">Tools</span>
        </a>
      </div>
      <div *ngIf="this.authService.isLoggedIn">
        <a mat-menu-item [routerLink]="['member']">
          <span class="mat-title">Member Tools</span>
        </a>
      </div>
       <a mat-menu-item routerLink="links"><span class="mat-title">Links</span></a>
       <button mat-menu-item [matMenuTriggerFor]="subMenu">
          <span class="mat-title">{{ authService.displayName }}</span>
       </button>
       <a mat-menu-item routerLink="help"><span class="mat-title">Help</span></a>
    </mat-menu>
    <mat-menu #subMenu="matMenu">
       <button mat-menu-item [disabled]="this.authService.isLoggedIn" (click)="openDialog()">
          login
       </button>
       <button mat-menu-item [disabled]="!this.authService.isLoggedIn" (click)="doLogout()">
          logout
       </button>
       <a mat-menu-item [disabled]="this.authService.isLoggedIn" routerLink="register">
          register
       </a>
    </mat-menu>
 </div>
</mat-toolbar>

<section class="mat-typography">
  <router-outlet></router-outlet>
</section>
<mat-toolbar class="bottom-banner" color="primary" fxLayout.lt-md="column">
  <span>&copy; 2022 {{ author }}</span>
  <span fxFlex="1 1 auto"></span>
  <span>Your Source for Cyber Security</span>
  <span fxFlex="1 1 auto"></span>
  <span>
    <a class="bottom-white" routerLink="."> 
      {{ server }}
    </a>
    <span class="mat-caption">&nbsp;(ver 2.0)</span>    
  </span>
  
</mat-toolbar>