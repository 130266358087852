<div class="main">
  <div fxLayout="column" fxLayoutGap="10px">
      <h2>Welcome to the Member Tools Section</h2>
      <p>
          Here you can manage all aspects of custom file encryption:<br>
      </p>
      <mat-tab-group>
        <mat-tab label="Algorithm Setup">
          <app-crypt></app-crypt>
        </mat-tab>
        <mat-tab label="Key Management">
          <app-keys></app-keys>
        </mat-tab>
        <mat-tab label="File Management">
          <app-files></app-files>
        </mat-tab>
      </mat-tab-group>
  </div>
</div>
