<div class="progress" *ngIf="spinner == 'show'">
  <mat-spinner>wait</mat-spinner>
</div>
<h3>Data Encryption Standard</h3>
<p>The Data Encryption Standard (DES) is a symmetric-key algorithm for the encryption of electronic data. 
  It was highly influential in the advancement of modern cryptography. DES was published as an official Federal Information
  Processing Standard (FIPS) for the United States in 1977. DES is now considered insecure. This is mainly due to the 56-bit key size being
  too small. DES has been withdrawn as a standard by the National Institute of Standards and Technology. The algorithm is
  believed to be practically secure in the form of Triple DES which uses a 168-bit key. DES uses a fixed block size of 64
  bits.
</p>
<p>
  Complete the 3 steps below:<br> (1) select your file: either a plain file, or a previously encrypted file; (2) select the
  desired parameters; and (3) start the encryption or decryption process.</p>
<form (ngSubmit)="onSubmit()" #desForm="ngForm">
  <app-select [request]="request"></app-select>
  <fieldset>
    <legend>2. Select Algorithm Parameters</legend>
    <div *ngIf="!gotKey()">
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxLayout="column" fxFlex="50">

          <div fxLayout="row" fxLayoutGap="5px">
            <div fxLayout="column" fxFlex="15" fxLayoutGap="12px" fxLayoutAlign="center end">
              <span>Key size:</span>
              <span>Mode:</span>
              <span>Key style:</span>
            </div>
            <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center start">
              <mat-radio-group [(ngModel)]="keySize" name="des.keySize">
                <mat-radio-button value="64">56</mat-radio-button>&nbsp;
                <mat-radio-button value="192">168 (Triple DES)</mat-radio-button>&nbsp;
              </mat-radio-group>
              <mat-divider></mat-divider>
              <mat-radio-group [(ngModel)]="request.mode" name="des.mode">
                <mat-radio-button value="ECB">Electronic Codebook</mat-radio-button>&nbsp;
                <mat-radio-button value="CBC">Cipher Block Chaining</mat-radio-button>
              </mat-radio-group>
              <mat-divider></mat-divider>
              <mat-radio-group [(ngModel)]="request.keyStyle" name="des.keyStyle">
                <mat-radio-button value="password">password</mat-radio-button>&nbsp;
                <mat-radio-button value="hex">hex data</mat-radio-button>
              </mat-radio-group>

            </div>
          </div>

        </div>
        <div fxLayout="column" fxFlex="50">

          <div *ngIf="request.keyStyle=='password'" appearance="legacy">
            <mat-form-field>
              <input matInput type="password" [(ngModel)]="request.password" name="password" placeholder="Password" size="10" required/>
              <mat-error>password is required</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="80" *ngIf="request.keyStyle=='hex'">
            <mat-form-field appearance="legacy">
              <input matInput type="text" [(ngModel)]="request.keyData" name="keyData" placeholder="Key data in {{+keySize/8}} hex digits "
                size="{{+keySize/4}}" required pattern="[a-fA-F0-9]{16}" />
              <mat-error>iv data is required</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="request.mode=='CBC'" appearance="legacy">
              <input matInput type="text" [(ngModel)]="request.ivData" name="ivData" placeholder="Initialization vector in 8 hex digits "
                size="16" required />
              <mat-error>iv data is required</mat-error>
            </mat-form-field>
          </div>
          <p>
            Or, you can preselect an existing key from the "Key Management" tab.
            <span *ngIf="authService.selectedKey !== null">Current selection is: {{getKeyData()}}</span>
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="gotKey()">
      Selected Key: {{authService.userId + "-" + authService.selectedKey}}: {{getKeyData()}} &nbsp;&nbsp;&nbsp; <button mat-button-raised
        (click)="resetKey()">Reset</button>
    </div>
  </fieldset>
  <fieldset>
    <legend>3. Start the Process</legend>
    <button mat-button-raised type="submit" [disabled]="desForm.invalid || !checkFile()">{{request.op}}</button>
    &nbsp;{{message}}
    <div *ngIf="desForm.invalid" mat-warn>
      some required fields are missing
    </div>
  </fieldset>
</form>