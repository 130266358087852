<div class="main">
  <h2>Frequently Asked Questions</h2>
  <hr>
  <ul class="faq">
    <li>How can I use more advanced encryption features ?
      <p>
        You need to login first. Click the Member entry in the top menu bar.
      </p>

      <li>How can I log in ?
        <p>
          Register to become a member of our user community. All you need is a valid email address.
        </p>
  </ul>

  <br>
  <br>
  <br>
  <br>
</div>