<fieldset class="mat-elevation-z6">
  <legend>
    We offer encryption using a variety of algorithms:
  </legend>
  <mat-tab-group>
    <mat-tab label="AES">
      <app-aes></app-aes>
    </mat-tab>
    <mat-tab label="DES">
      <app-des></app-des>
    </mat-tab>
    <mat-tab label="RC4">
      coming soon ...
    </mat-tab>
  </mat-tab-group>
</fieldset>